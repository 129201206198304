






































































































































































































































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'ChemMcM1AA3Lab10Results1',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        massSA: null,
        massASA: null,
        mwSA: null,
        mwASA: null,
        molSA: null,
        // molASA: null,
        theoYield: null,
        pctYield: null,
        pctYieldDiscussion: null,
        mpASA1: null,
        mpASA2: null,
        mpSA1: null,
        mpSA2: null,
        mpProd1: null,
        mpProd2: null,
        mpDiscussion: null,
        RfASA: null,
        RfSA: null,
        RfProd: null,
        tlcDiscussion: null,
        observationsH2SO4Rxn: null,
        observationsFeCl3Rxn: null,
        conclusion: null,
      },
      attachments: [] as File[],
    };
  },
});
